import cssVars from "css-vars-ponyfill";
const isSupportCssVar = window.CSS && window.CSS.supports && window.CSS.supports("(--a: 0)");
import resources from "@kgsport-cms/theme";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import theme from "@kgsport-cms/style/es/variables";
const developmentKey = "CMS_DEVELOPMENT_CONFIG";
const config = localStorage.getItem(developmentKey);
window.CONFIG = Object.assign(window.CONFIG || {}, JSON.parse(config || "{}"));
const resource = resources[window.CONFIG?.name || "assets"] || resources.assets;
const themeH5 = window.CONFIG.themeH5 || window.CONFIG?.theme || "default";
const themeDefault = window.isMobile ? themeH5 : window.CONFIG?.theme || "default";
const variables = {
  ...theme[themeDefault],
  ...resource.css,
};
const formatVar = (variable: Record<string, string> = {}, isJoin = true) => {
  const cssvar = Object.keys(variable)
    .map(item => `${item}:${variable[item]};\n`)
    .join("");
  return isJoin ? `:root{\n${cssvar}}` : cssvar;
};
const cssRootVar = (variable: Record<string, any> = {}, selector = "style[data-theme='theme']") => {
  const root = selector
    ? document.querySelector(selector) || document.createElement("style")
    : document.createElement("style");
  root.innerHTML = formatVar(variable);

  if (!root.getAttribute("data-theme")) {
    root.setAttribute("data-theme", selector || "theme");
    const header = document.head || document.getElementsByName("head")[0];
    if (!header) return;
    header.appendChild(root);
  }
};
// 更新配置
const configUpdate = (config: Record<any, any> = {}) => {
  const base: Config = Object.assign(window.CONFIG || {}, config);
  localStorage.setItem(developmentKey, JSON.stringify(base));
  if (window.CONFIG) {
    window.CONFIG = base;
    return;
  }
  window.CONFIG = base;
};

window.cms_common = {
  variables,
  themeDefault,
  theme,
  resource,
  developmentKey,
  cssRootVar,
  formatVar,
  configUpdate,
};

(function () {
  isSupportCssVar
    ? cssRootVar(variables)
    : cssVars({
        variables,
        shadowDOM: false,
        updateDOM: true,
        onlyLegacy: process.env.NODE_ENV === "development",
        silent: true,
        updateURLs: true,
        preserveVars: false,
        include: "link[rel=stylesheet],style",
        watch: true,
      });
})();
