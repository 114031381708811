export default {
  "themes": [
    "bbBlue",
    "bgolden",
    "custom",
    "dark",
    "default",
    "golden",
    "green",
    "kgBlue",
    "kgGolden",
    "kgGreen",
    "purple",
    "red"
  ],
  "bbBlue": {
    "--cms-primary-color": "#3270e1",
    "--cms-primary-color-hover": "#3d72fa",
    "--cms-primary-lite": "#57a0fe",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#62b3ff",
    "--cms-geekblue-lite-color": "#73a2ff",
    "--cms-geekblue-1-color": "#90f0ff",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#63ccf6,#1783f5)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#414655",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#79b7ee",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#2b2a2a",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "#468aff",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "bgolden": {
    "--cms-primary-color": "#ffe18e",
    "--cms-primary-color-hover": "#d8b58d",
    "--cms-primary-lite": "#fdb646",
    "--cms-primary-border": "#120e05",
    "--cms-geekblue-color": "#b89866",
    "--cms-geekblue-lite-color": "#ffba61",
    "--cms-geekblue-1-color": "#ff9000",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#f3b945,#f09f18)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "linear-gradient(#f8d96e,#937b2c)",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#130d01",
    "--cms-background-card-color": "#1d1409",
    "--cms-title-card-color": "#79420d",
    "--cms-title-color": "#ae9372",
    "--cms-nav-background": "#423119",
    "--cms-nav-border": "#2a200e",
    "--cms-hint-text": "#9d8972",
    "--cms-vip-slider": "#daac79",
    "--cms-form-title-color": "#d7bd9e",
    "--cms-account-btn-color": "linear-gradient(298deg,#eec097,#bd782c)",
    "--cms-message-color": "#92897a",
    "--cms-withdraw-color": "var(--cms-primary-color)",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(90deg,#ffda90,#d2ae67)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#ffe7a1,#ffeed1)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#ffd073,#ffdd9c)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#ffd073,#ffec82)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#ffd9b2,#fd9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#ffc552,#ffc282)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-background-card-color)",
    "--cms-account-text-color": "var(--cms-title-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "var(--cms-geekblue-1-color)",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "custom": {
    "--cms-primary-color": "#4176fa",
    "--cms-primary-color-hover": "var(----cms-primary-color)",
    "--cms-primary-lite": "var(----cms-primary-color)",
    "--cms-primary-border": "var(----cms-primary-color)",
    "--cms-geekblue-color": "var(----cms-primary-color)",
    "--cms-geekblue-lite-color": "var(----cms-primary-color)",
    "--cms-geekblue-1-color": "var(----cms-primary-color)",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#63ccf6,#1783f5)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#414655",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#79b7ee",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#2b2a2a",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "var(----cms-primary-color)",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "dark": {
    "--cms-primary-color": "#ffe4c8",
    "--cms-primary-color-hover": "#d8b58d",
    "--cms-primary-lite": "#efad63",
    "--cms-geekblue-color": "#ef9f4c",
    "--cms-primary-border": "#e7d4b4",
    "--cms-geekblue-lite-color": "#ffba61",
    "--cms-geekblue-1-color": "#fab469",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#f5e483,#e7b660)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#1b1b1b",
    "--cms-loading-text-color": "#ffe4c8",
    "--cms-background-color": "#000",
    "--cms-background-card-color": "#1b1b1b",
    "--cms-title-card-color": "#79420d",
    "--cms-title-color": "#dabd9b",
    "--cms-nav-background": "#292929",
    "--cms-nav-border": "#333",
    "--cms-hint-text": "#9d8972",
    "--cms-vip-slider": "#daac79",
    "--cms-form-title-color": "#d7bd9e",
    "--cms-account-btn-color": "linear-gradient(298deg,#eec097,#bd782c)",
    "--cms-message-color": "#92897a",
    "--cms-withdraw-color": "var(--cms-primary-color)",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(90deg,#ffda90,#d2ae67)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#ffe7a1,#ffeed1)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#ffd073,#ffdd9c)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#ffd073,#ffec82)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#ffd9b2,#fd9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#ffc552,#ffc282)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-background-card-color)",
    "--cms-account-text-color": "var(--cms-title-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "var(--cms-geekblue-1-color)",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "default": {
    "--cms-primary-color": "#4176fa",
    "--cms-primary-color-hover": "#3d72fa",
    "--cms-primary-lite": "#57a0fe",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#62b3ff",
    "--cms-geekblue-lite-color": "#73a2ff",
    "--cms-geekblue-1-color": "#90f0ff",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#63ccf6,#1783f5)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#414655",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#79b7ee",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#2b2a2a",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "#468aff",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "golden": {
    "--cms-primary-color": "#ff9000",
    "--cms-primary-color-hover": "#fe8f00",
    "--cms-primary-lite": "#fdb646",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#f8d96e",
    "--cms-geekblue-lite-color": "#ffba61",
    "--cms-geekblue-1-color": "#ff9000",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#f3b945,#f09f18)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#fff",
    "--cms-loading-text-color": "#ff9000",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#faaf4d",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "#ff9000",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "green": {
    "--cms-primary-color": "#069b71",
    "--cms-primary-color-hover": "#00af81",
    "--cms-primary-lite": "#017159",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#1bcb37",
    "--cms-geekblue-lite-color": "rgba(6,155,112,.96)",
    "--cms-geekblue-1-color": "#05926a",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#6dad27,#1c9339)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#000",
    "--cms-loading-text-color": "#069b71",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#79b7ee",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "kgBlue": {
    "--cms-primary-color": "#3f4b83",
    "--cms-primary-second-color": "#606da5",
    "--cms-primary-third-color": "#404b7a",
    "--cms-primary-third-text-color": "#303c76",
    "--cms-primary-background-color": "#000",
    "--cms-primary-background-second-color": "#e2e2e2",
    "--cms-primary-background-third-color": "#22342f",
    "--cms-primary-background-forth-color": "#13182c",
    "--cms-primary-background-nav-color": "var(--cms-primary-third-color)",
    "--cms-primary-background-from-border-color": "var(--cms-primary-third-color)",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-primary-second-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-primary-third-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-third-text-color) 1%,var(--cms-primary-color))",
    "--cms-border-color": "#3f4b83",
    "--cms-primary-text-color": "#fff",
    "--cms-header-btn-color": "#ffd668",
    "--cms-header-btn-second-color": "#ae5e00",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#fff",
    "--cms-loading-text-color": "#3ff1b2",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#faaf4d",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#566fdf,#4f67d4)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#264b83,#704b83)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#1d4b83,#5b4b83)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#1c4b83,#1e4b83)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#404b83,#074b83)"
  },
  "kgGolden": {
    "--cms-primary-color": "#ffda90",
    "--cms-primary-second-color": "#ffd577",
    "--cms-primary-third-color": "#c69c3e",
    "--cms-primary-third-text-color": "#968366",
    "--cms-primary-background-color": "#000",
    "--cms-primary-background-second-color": "#2e2e2e",
    "--cms-primary-background-third-color": "#22342f",
    "--cms-primary-background-forth-color": "#1b1b1b",
    "--cms-primary-background-nav-color": "var(--cms-primary-background-second-color)",
    "--cms-primary-background-from-border-color": "var(--cms-primary-second-color)",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-primary-second-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-primary-third-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-third-text-color) 1%,var(--cms-primary-color))",
    "--cms-border-color": "#4c483f",
    "--cms-primary-text-color": "#fff",
    "--cms-header-btn-color": "#ffd668",
    "--cms-header-btn-second-color": "#ae5e00",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#fff",
    "--cms-loading-text-color": "#3ff1b2",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#faaf4d",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(90deg,#ffda90,#d2ae67)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#ffe7a1,#d2ae67)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#ffd073,#ffdd9c)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#ffd073,#ffec82)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#ffd9b2,#fd9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#ffc552,#ffc282)"
  },
  "kgGreen": {
    "--cms-primary-color": "#069b71",
    "--cms-primary-second-color": "#1b8c63",
    "--cms-primary-third-color": "#126e51",
    "--cms-primary-third-text-color": "#1f654c",
    "--cms-primary-background-color": "#000",
    "--cms-primary-background-second-color": "#1d332e",
    "--cms-primary-background-third-color": "#22342f",
    "--cms-primary-background-forth-color": "#1b1b1b",
    "--cms-primary-background-nav-color": "var(--cms-primary-third-color)",
    "--cms-primary-background-from-border-color": "var(--cms-primary-third-color)",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-primary-second-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-primary-third-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-third-text-color) 1%,var(--cms-primary-color))",
    "--cms-border-color": "#069b71",
    "--cms-primary-text-color": "#fff",
    "--cms-header-btn-color": "#ffd668",
    "--cms-header-btn-second-color": "#ae5e00",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#fff",
    "--cms-loading-text-color": "#3ff1b2",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#faaf4d",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#3ff1b2,#33d744)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#26e246,#70d665)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#1de935,#5be64f)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#1cef2e,#1ee17c)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#40f024,#07ef4d)"
  },
  "purple": {
    "--cms-primary-color": "#400464",
    "--cms-primary-color-hover": "#6412a2",
    "--cms-primary-lite": "#9a24f4",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#7813c5",
    "--cms-geekblue-lite-color": "#7313bc",
    "--cms-geekblue-1-color": "#90f0ff",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-geekblue-lite-color) 1%,var(--cms-primary-color))",
    "--cms-header-avatar-color": "linear-gradient(130deg,#6312a2,#6f6dba)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#414655",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#7912a2",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#464646,#2b2a2a)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#2b2a2a",
    "--cms-nav-item-logo-color": "#414655",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--swiper-theme-color": "var(--cms-primary-color)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "#4612a2",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  },
  "red": {
    "--cms-primary-color": "#e4393c",
    "--cms-primary-color-hover": "#d2161a",
    "--cms-primary-lite": "#d86c6e",
    "--cms-primary-border": "#dce1e5",
    "--cms-geekblue-color": "#fa6164",
    "--cms-geekblue-lite-color": "#f15f61",
    "--cms-geekblue-1-color": "#c05555",
    "--cms-gradient-color": "linear-gradient(90deg,var(--cms-geekblue-color),var(--cms-primary-color))",
    "--cms-gradient-lite-color": "linear-gradient(90deg,var(--cms-geekblue-1-color),var(--cms-primary-color))",
    "--cms-gradient-btn-color": "var(--cms-gradient-color)",
    "--cms-header-avatar-color": "linear-gradient(130deg,#ea4549,#cd2524)",
    "--cms-header-btn-color": "linear-gradient(0deg,#ffd668,#ae5e00)",
    "--cms-bateRate-color": "#ff0d00",
    "--cms-loading-background-color": "#f0f6ff",
    "--cms-loading-text-color": "#e4393c",
    "--cms-background-color": "#f0f6ff",
    "--cms-background-card-color": "#fff",
    "--cms-title-card-color": "#fff",
    "--cms-title-color": "#414655",
    "--cms-nav-background": "#f6faff",
    "--cms-nav-border": "#e4eaff",
    "--cms-hint-text": "#6c7ba8",
    "--cms-vip-slider": "#fb4245",
    "--cms-form-title-color": "#6a7391",
    "--cms-account-btn-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-message-color": "#a5a9b3",
    "--cms-withdraw-color": "#6aaaf5",
    "--cms-nav-item-logo-color": "#820000",
    "--cms-bank-card-nth-color": "linear-gradient(298deg,#d0d2db,#e6e9f3)",
    "--cms-bank-card-nth1-color": "linear-gradient(90deg,#4ca8f1,#4ca2ec)",
    "--cms-bank-card-nth2-color": "linear-gradient(90deg,#50b5f5,#4e89e4)",
    "--cms-bank-card-nth3-color": "linear-gradient(90deg,#47abf5,#50b5f5)",
    "--cms-bank-card-nth4-color": "linear-gradient(90deg,#4ca2ec,#0c66a9)",
    "--cms-bank-card-nth5-color": "linear-gradient(90deg,#62a9e9,#3d88d3)",
    "--ant-primary-color": "var(--cms-primary-color)",
    "--cms-account-background": "var(--cms-primary-color)",
    "--cms-account-text-color": "var(--cms-background-card-color)",
    "--van-blue": "var(--cms-primary-color)",
    "--van-tabs-bottom-bar-color": "var(--cms-primary-color)",
    "--van-notice-bar-text-color": "var(--cms-primary-color)",
    "--cms-home-title": "#e4393c",
    "--van-swipe-indicator-active-background-color": "var(--cms-primary-color)"
  }
};